import { Analytics } from '@/lib/analytics'
import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { startTransition, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'

Sentry.init({
  dsn:
    // only run sentry in production
    process.env.NODE_ENV === 'production'
      ? 'https://6941d56f987f4185a2d4d2666053c7d0@o4505114171146240.ingest.us.sentry.io/4505190321815552'
      : undefined,
  tracesSampleRate: 1,

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
})

startTransition(() => {
  hydrateRoot(
    document,
    <>
      <RemixBrowser />
      <Analytics.Bootstrap />
    </>,
  )
})
